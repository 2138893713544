nav {
    position: absolute;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    top: 50px;
    left: 50%;
    transform: translate(-50%);
}
nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    margin: 4px;
}
nav ul li {
    height: 52px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}
nav ul li:first-child {
    padding-left: 25px;
}
nav ul li:last-child {
    padding-right: 25px;
}
nav ul a {
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.18px;
}
nav > ul > li:nth-child(5) a {
    min-width: 76px;
}
.nav-buttons {
    position: absolute;
    display: flex;
    gap: 8px;
    right: 5%;
    top: 20px;
    display: none;
    z-index: 1;
}
#burger,
.contact-button {
    height: 45px;
    background-color: white;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 30px;
    display: flex;
}
.contact-button {
    background-color: black;
}
.contact-button a {
    color: white;
}
.burger-off {
    display: none;
}
@media (min-width: 776px) {
    .active-pathway {
        background-color: black;
        color: white;
    }
}
@media (max-width: 776px) {
    nav {
        top: 70px;
        left: 0;
        width: 100%;
        height: 30%;
        transform: none;
        z-index: 100;
        background-color: #EBEBEB;
    }
    nav ul {
        margin: 16px 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
    }
    nav ul li {
        height: auto;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nav-buttons {
        display: flex;
    }
    .active-pathway {
        text-decoration: underline;
    }
}
