.header {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
}
.contact_line {
    padding: 65px 100px 0 100px;
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
}
.contact_line a {
    opacity: 0.6;
}
.shouter-line {
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    width: 100%;
    padding: 0 100px 100px;
}
.shouter-line > div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}
.shouter-line > div > p {
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    letter-spacing: -0.18px;
    padding-top: 16px;
}
.shouter-line > div > div {
    display: flex;
    gap: 40px;
    padding-bottom: 12px;
}
.shouter-line > div > div p {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: -0.16px;
}
.shouter-line > div:nth-child(2) {
    justify-self: flex-end;
}
.shouter-line > div:nth-child(2) h3 {
    font-size: 4.9vw;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 112.8px */
    letter-spacing: -1.88px;
}
@media (max-width: 1300px) {
    .shouter-line > div:nth-child(2) h3 {
        font-size: 62px;
    }
    .shouter-line {
        padding: 50px 100px;
    }
}
@media (max-width: 992px) {
    .header {
        height: auto;
    }
    .shouter-line {
        position: static;
        padding-top: 100px;
    }
    .contact_line {
        padding: 8px 32px;
    }
}
@media (max-width: 776px) {
    .contact_line {
        padding: 32px 24px 88px;
    }
    .contact_line a:last-child {
        display: none;
    }
    .shouter-line {
        position: static;
        padding: 0 32px;
    }
    .shouter-line > div > div {
        padding: 24px 0 62px;
    }
    .shouter-line > div:nth-child(2) h3 {
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 115%; /* 55.2px */
        letter-spacing: -0.96px;
    }
    .shouter-line > div:nth-child(2) br {
        display: none;
    }
}
