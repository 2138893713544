.case-studies-preview {
    position: relative;
    width: 90%;
    margin: 80px auto;
    overflow: hidden;
    border-radius: 50px;
}
.case-studies-preview img {
    /* height: 90vh; */
    border-radius: 50px;
    width: 100%;
}
.case-studies-preview section a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
}
.case-studies-preview > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: lightgray;
    transition: opacity 1s, visibility 0s linear 1s;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    padding: 80px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.case-studies-preview:hover > div {
    opacity: 0.85;
    visibility: visible;
    transition: opacity 1s;
}
.case-studies-preview > div h1 {
    font-size: max(2.5vw, 16px);
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 52.8px */
    letter-spacing: -0.48px;
}
.case-studies-preview > div a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: black;
    border-radius: 50px;
    padding: 16px 0;
    width: 15%;
}
.case-studies-preview > div > div > div > h1:nth-child(2) {
    padding: max(1.6vw, 8px) 0 max(3.2vw, 16px);
}
.case_tablet,
.case_phone {
    display: none;
}

@media (max-width: 768px) {
    .case-studies-preview {
        border-radius: 0;
        margin: 0 auto;
    }
    .case-studies-preview img {
        height: auto;
        width: 70%;
        margin: 0 auto;
    }
    .case_desk {
        display: none;
    }
    .case_tablet {
        display: block;
    }
    .case-studies-preview > div {
        position: static;
        visibility: visible;
        opacity: 1;
        padding: 24px 0 40px;
        background-color: #F5F5F5;
    }
    .case-studies-preview > div a {
        display: none;
    }
    .case-studies-preview > div > div {
        display: flex;
        justify-content: space-between;
    }
    .case-studies-preview section a {
        display: block;
    }
}
@media (max-width: 475px) {
    .case_tablet {
        display: none;
    }
    .case_phone {
        display: block;
    }
    .case-studies-preview img {
        height: auto;
        width: 90%;
        margin: 0 auto;
    }
}