@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: 'Manrope', sans-serif;
  scroll-behavior: smooth;
}
html {
  font-size: 16px;
}
body {
  padding: 0;
  margin: 0;
  background-color: #F5F5F5;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  color: black;
}

@media (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 776px) {
  html {
    font-size: 12px;
  }
}
